var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: _vm.handleScroll,
          expression: "handleScroll",
        },
      ],
      attrs: { id: "landing-page" },
    },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            "mini-variant": _vm.miniVariant,
            right: true,
            fixed: "",
            app: "",
            temporary: "",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-menu" },
            [
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          _vm.drawer = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("h2", [_vm._v(_vm._s(_vm.$t("menu")))]),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-5 mb-7" }),
              _vm._v(" "),
              _c("div", { staticClass: "my-2" }, [
                _c("span", { staticClass: "menu-item fw-medium" }, [
                  _vm._v(_vm._s(_vm.$t("services"))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pl-3 my-2 cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.navigateToAndScroll("services-section", "/")
                    },
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "menu-item",
                      class: { active: _vm.active.service1 },
                    },
                    [_vm._v(_vm._s(_vm.$t("engagementServices")))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pl-3 my-2 mb-5 cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.navigateToAndScroll(
                        "instagram-flatrate-section",
                        "/"
                      )
                    },
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "menu-item",
                      class: { active: _vm.active.service2 },
                    },
                    [_vm._v(_vm._s(_vm.$t("igFlatrateTitle")))]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.$auth && _vm.$auth.user
                ? [
                    _c(
                      "nuxt-link",
                      { attrs: { to: _vm.localePath("/dashboard/home") } },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "menu-item fw-medium",
                            class: { active: _vm.active.dashboard },
                          },
                          [_vm._v(_vm._s(_vm.$t("dashboard")))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "mt-5 mb-7" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "custom-icon", attrs: { small: "" } },
                          [_vm._v("mdi-logout")]
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "menu-item",
                            on: { click: _vm.logoutWarning },
                          },
                          [_vm._v(_vm._s(_vm.$t("logout")))]
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _vm.$auth && !_vm.$auth.user
                      ? _c(
                          "nuxt-link",
                          {
                            staticClass: "my-2",
                            attrs: { to: _vm.localePath("/login") },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "menu-item fw-medium",
                                class: { active: _vm.active.login },
                              },
                              [_vm._v(_vm._s(_vm.$t("login")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "mt-7 mb-5" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _vm.$auth && !_vm.$auth.user
                          ? _c(
                              "nuxt-link",
                              {
                                attrs: {
                                  to: _vm.localePath("/account/register"),
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "text-16 custom-btn",
                                    attrs: {
                                      depressed: "",
                                      color: "btn-brand",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("getStarted")))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        {
          staticClass: "header-container",
          class: { "bg-on-scroll": _vm.isAppBarPersistent },
          attrs: { fixed: "", light: "", app: "", flat: "", color: "white" },
        },
        [
          _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
            _c("img", {
              staticClass: "cursor-pointer",
              attrs: {
                src: require("assets/images/logo-text.svg"),
                alt: "Logo",
                width: "190",
                height: "50",
                loading: "lazy",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-auto align-center d-none d-sm-flex" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    transition: "scale-transition",
                    "open-on-hover": "",
                    "offset-y": "",
                    "min-width": "auto",
                    "content-class": "services-menu",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "label",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass:
                                    "mr-1 menu-item d-flex align-center cursor-pointer",
                                  class: { active: _vm.active.services },
                                },
                                "label",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("span", [_vm._v(_vm._s(_vm.$t("services")))]),
                              _vm._v(" "),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { size: "20" } },
                                [_vm._v("mdi-chevron-down")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { attrs: { outlined: "", "max-width": "350" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "py-2 px-1" },
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.navigateToAndScroll(
                                    "services-section",
                                    "/"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-3 my-3" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-brand",
                                      attrs: { size: "24" },
                                    },
                                    [_vm._v("mdi-instagram")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(_vm.$t("engagementServices"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.navigateToAndScroll(
                                    "instagram-flatrate-section",
                                    "/"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-3 my-3" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-brand",
                                      attrs: { size: "26" },
                                    },
                                    [_vm._v("mdi-star-outline")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("igFlatrateTitle"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.$auth && !_vm.$auth.user
                ? _c("nuxt-link", { attrs: { to: _vm.localePath("/login") } }, [
                    _c(
                      "span",
                      {
                        staticClass: "mx-12 menu-item",
                        class: { active: _vm.active.login },
                      },
                      [_vm._v(_vm._s(_vm.$t("login")))]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$auth && !_vm.$auth.user
                ? _c(
                    "nuxt-link",
                    { attrs: { to: _vm.localePath("/account/register") } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-16 custom-btn",
                          attrs: { depressed: "", color: "btn-brand" },
                        },
                        [_vm._v(_vm._s(_vm.$t("getStarted")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$auth && _vm.$auth.user
                ? _c(
                    "nuxt-link",
                    { attrs: { to: _vm.localePath("/dashboard/home") } },
                    [
                      _c("span", { staticClass: "mr-8 ml-12 menu-item" }, [
                        _vm._v(_vm._s(_vm.$t("dashboard"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$auth && _vm.$auth.user
                ? _c(
                    "div",
                    { staticClass: "ml-3" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "ml-5 mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-logout")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "menu-item cursor-pointer",
                          on: { click: _vm.logoutWarning },
                        },
                        [_vm._v(_vm._s(_vm.$t("logout")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-app-bar-nav-icon", {
            staticClass: "ml-auto d-sm-none",
            attrs: { "aria-label": "Sidebar Menu Button" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-main", [
        _c(
          "div",
          {
            ref: "mainContent",
            attrs: { id: "main-content" },
            on: { scroll: _vm.handleScroll },
          },
          [_c("Nuxt")],
          1
        ),
      ]),
      _vm._v(" "),
      _c("modal-notification", {
        ref: "logoutWarning",
        attrs: { data: _vm.modalWarningObj },
        on: { leave: _vm.logout },
      }),
      _vm._v(" "),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay, opacity: "0.2" } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }