
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  layout: 'landing-page',
})
export default class LandingPageLayout extends Vue {
  drawer: boolean = false;
  miniVariant: boolean = false;
  active: any = { login: false, service1: false, service2: false };
  loggedIn: boolean = true;
  modalWarningObj: any = {};
  overlay: boolean = false;

  isAppBarPersistent: boolean = false;

  handleScroll() {
    this.isAppBarPersistent = window.scrollY > 0 ? true : false;
  }
  
  @Watch('$route')
  checkRoute() {
    this.check();
  }

  mounted() {
    this.check();
    this.loggedIn = this.$auth?.user ? true : false;

    window.addEventListener('scroll', this.handleScroll);
  }

  beforeDestroy() {
    // Remove scroll event listener when component is destroyed
    window.removeEventListener('scroll', this.handleScroll);
  }

  check() {
    const route = this.$route as any;
    if(route) {
      this.active = {
        home: route.name == 'index' ? true : false,
        services: route.name == 'instagram-flatrate' || route.name == 'mobile-proxy-api' ? true : false,
        explore: route.name == 'explore' ? true : false,
        login: route.name == 'login' ? true : false,
        instagram: route.name == 'instagram-flatrate' ? true : false,
        proxy: route.name == 'mobile-proxy-api' ? true : false,
      }
    }
  }

  async logoutWarning() {
    this.modalWarningObj.subTitle = this.$t('areYouSureLogout');
    this.modalWarningObj.btnLabel = this.$t('logout');
    const modal = this.$refs.logoutWarning as any;
    modal.show();
  }

  async logout() {
   try {
    await this.$auth.logout();
   } catch (error) {
    console.error('Error logging out:', error);
   }
  }

  navigateToAndScroll(id:any, routePath:any) {
    if (this.$route.path !== routePath) {
      // Navigate to the specified route
      this.$router.push(routePath).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    }
    this.$nextTick(() => {
      this.scrollToElement(id);
    });
  }

  scrollToElement(id:any) {
    const element = document.getElementById(id) as any;
    if(element) {
      const offset = 50;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
  }
}
